.footer-wrapper {
    background-color: #eee;
    border-top: 1px solid black;
    padding-top: 50px;

    
    @media (max-width: 768px) {
        display: block;
       }
    .footer-first {
        @media (max-width: 768px) {
            display: block;
            padding-bottom: 250px;
           }
        padding-bottom: 100px;
        border-bottom: 1px solid black;
        display: flex;
        justify-content: space-around;

        .footer-img {
            img {
                width: 250px;
                height:80px;
                margin-top: 30px;
                @media (max-width: 768px) {
                    width: 90%;
                    height: auto;
                margin-left: 15px;
                    
                   }
            }
        }

        .footer-left {
            h1 {
                font-size: 20px;
                margin-left: -50px;

                @media (max-width: 768px) {
                    margin-left: 15px;
                    margin-top: 30px;
                    font-size: 25px;
                   }
            }
          

            a {
                text-transform: uppercase;
                margin-bottom: 20px;
                text-decoration: none;
                color: #898989;
                display: block;

                @media (max-width: 768px) {
                   margin-left: 65px;
                   }

                //gap: 2px;
            }
            a:hover{
                color: black;
            }
        }

        .footer-right {
             @media (max-width: 768px) {
                 display: block;
                 margin-left: 15px;
                }
            height: 100px;
            margin: 0;
            padding: 0;
            margin-left: -60px;

           
            h1 {
                font-size: 20px;
                
 @media (max-width: 768px) {
                margin-left: 5px;
                margin-top: 30px;
                font-size: 25px;
               }
               
            }

            h4 {
                margin-top: -10px;
                font-weight: 400;
                color: #898989;
                @media (max-width: 768px) {
                    margin-left: 55px;
                   
                   }
            }

            p {
                margin-top: -20px;
                color: #898989;
                @media (max-width: 768px) {
                    margin-left: 55px;
                   
                   }
            }
            p:hover{
                color: black;
            }
        }

    }

    .footer-sec {
       
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
            display: block;
            text-align: center;
          margin-left: -130px;
           }

        p {
            margin-left: 100px;
            font-size: 18px;

          

            b {
                color: black;
            }
        }

        .icons {
          
            padding-right: 50px; 
            
            @media (max-width: 768px) {
                margin-left: 150px;
               
               }
            svg:hover{
                cursor: pointer;
                fill: black;
                transition: 1s fill ease-in-out;
            }
             }
       
        
       
    }
}