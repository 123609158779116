.kontakt-wrapper {

    background-color: #eee;
    padding-bottom: 20px;
    padding-top: 100px;

    .kontakt-text {
        padding-left: 130px;
        @media (max-width: 768px) {
            padding-left: 20px;
            
            }

        h1 {
            color:grey;

        }

        h4 {
            color: #898989;
            font-weight: 500;
           

        }

        p {
            font-weight: 400;
            font-size: 50px;
           
        }
    }

    .kontakt-form {
        display: flex;
        justify-content: center;
        gap: 200px;

        @media (max-width: 768px) {
           display: block;
           margin-left: 20px;
                
                }

        .form-text {
            h5 {
                font-size: 20px;
            }

            p {
                font-size: 18px;
                color: #898989;
            }
        }

        .form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            #name,
            #email,
            #phone {
                font-size: 16px;
                padding-left: 10px;
                width: 400px;
                height: 60px;
                background-color: transparent;
                border: 1px solid #898989;
                border-radius: 5px;
                @media (max-width: 768px) {
                    width: 90%;
                        
                        }
            }

            #text {
                font-size: 16px;
                padding-left: 10px;
                background-color: transparent;
                border: 1px solid #898989;
                @media (max-width: 768px) {
                    width: 90%;
                        
                        }
            }

            #phone:hover {
                border: 1px solid black;
            }

            #name:hover {
                border: 1px solid black;
            }

            #email:hover {
                border: 1px solid black;
            }

            #text:hover {
                border: 1px solid black;
            }
            button {
             
                width: 200px;
                height: 50px;
                background-color: red;
                color: white;
                border: red;
                border-radius: 5px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 13px;
            }
        
            button:hover {
                background-color: transparent;
                color: red;
                border: 1px solid red;
                cursor: pointer;
                transition: 0.5s ease-in-out;
            }


        }

    }
    .mapp{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 50px;
        @media (max-width: 768px) {
            width: 90%;
                margin-left: 20px;
                }
    }
    
}