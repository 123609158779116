.impresum{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 150px;
    margin-bottom: 150px;
    text-align: center;

    h1{
        font-size: 40px;
    }
}