.modal {
    position: fixed;
    background-color:transparent;
    top: 240px;
    left:-200px;
    width: 2000px;
    height:350px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: 768px){
        left: -70px;
        width: 500px;
        
     }
     img{
        width: 400px;
        @media(max-width: 768px){
            
            width: 100px;
            
         }
    
     }
     p{
        width: 400px;
        @media(max-width: 768px){
            
            width: 200px;
            
         }
     }

     a{
        text-decoration: none;
        color: red;
        text-transform: uppercase;
     }
     

    .content-modal {
        position: relative;
        color: black;
        background-color:#eee;
        max-width: 50%;
        min-width: 50%;
        padding: 30px 15px;
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border: 1px solid black;
    }

    .project-modal{
       
        img{
            width: 500px;
            height: 400px;
            @media(max-width: 768px){
                
                width: 200px;
                height: 150px;
                
             }

        }
        p{
            @media(max-width: 768px){
             
                margin-top: 30px;
                margin-bottom: -10px;
            }
        }
        
        .btn{
            width: 100px;
            height: 30px;
            background-color: red;
            color: white;
            font-size: 15px;
            border-radius: 5px;
            margin: 20px;
            border: 1px solid red;
            
        }
        .btn:hover{
            background-color: transparent;
            color: red;
            transition: 0.5s ease-in-out;
        }
    }

    .btn-close {
        background-color: #eee;
        position: absolute;
        top: 25px;
        color: black;
        border-radius: 50%;
        border:1px solid black;
        right: 45px;
        width: 50px;
        height: 50px;
        @media(max-width: 500px){
            top: 5px;
            right: 5px;
         }

        &-bg {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }
}
.mod-img{
    width:200px;
    @media(max-width: 500px){
        width: 100px;
     }
}