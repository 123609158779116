.uber-wrapper {
    background-color: #eee;
    padding-bottom: 20px;
    @media (max-width: 768px) {
       display: block;
       
       }

    .uber {
      
        color: grey;
        padding-top: 100px;
        padding-left: 100px;
    
        @media (max-width: 768px) {
           padding-left: 10px;
           font-size: 20px;


           
           }

           h1{
            @media (max-width: 768px) {
                
                font-size: 20px;
     
     
                
                }
           }

    }

    .uber-firsttext {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 768px) {
          display: block;
           
           }

        h2 {
            font-weight: 400;

            @media (max-width: 768px) {
                font-size: 20px;
               
               }
        }

        img {
            margin-top: 20px;
            width: 600px;
            margin-bottom: 20px;
        
            @media (max-width: 768px) {
             width: 90%;
             margin-left: 10px;
               
               }
        }
    }

    .uber-number {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 200px;
        padding-top: 100px;
        padding-bottom: 100px;
        margin-top: -150px;
        @media (max-width: 768px) {
           display: block;
           margin-left: 20px;
           }
        .number {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 200px;

            @media (max-width: 768px) {
                display: block;
                
                }
            .box {

                width: 150px;
                height: 120px;
                background-color: rgb(249, 246, 246);

                
                h3 {

                    color: grey;
                    font-size: 50px;
                    font-weight: normal;

                }

                p {
                    width: 200px;
                    margin-top: -40px;
                    margin-left: 45px;
                    color: #898989;
                 
                }
            }
        }

        .number-text {
            h4 {
                font-size: 25px;
            }

            p {
                color: #898989;
                width: 500px;
                @media (max-width: 768px) {
                    font-size: 20px;
                    width: 330px;
                    }

            }
        }
    }

    .we {
      width: 100%;



        .we-text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-bottom: 100px;

            h1 {
                text-transform: uppercase;
                color:#898989;
                font-weight: normal;
                font-size: 20px;

             

            }

            h3 {
                color: grey;
                font-size: 45px;
                margin-top: -3px;
                width: 100%;

                @media(max-width:768px){
                    font-size: 30px;
                }
            }
        }

        .boxes{
            display: flex;
            align-items: center;
            justify-content: center;

            @media(max-width:768px){
                // display: block;
                // text-align: center;
                // margin-left: 50px;
                // margin-right: 50px;
                flex-direction: column;

            }

           .boxes1{
            display: flex;
          gap: 20px;
          @media(max-width:768px){
            display: block;
        }

            .box{
                width: 280px;
                height: auto;
                text-align: center;
                display: flex;
                
                align-items: center;
                flex-direction: column;

                h2{

                    font-size: 20px;
                    @media(max-width:768px){
                        font-size: 15px;
                    }
                }
                p{
                    font-size: 13px;
                    width: 200px;
                }
                svg {
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    transition: ease-in-out 0.3s;
    
                    &:hover {
                        transform: translate();
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                        transform: translateY(-4px);
                    }
    
                    cursor: pointer;
                }

            }
           }
        }
        .we-box {
            display: flex;
            justify-content: center;
            align-items: center;
            //gap: 50px;
             margin-top: 300px;
             

            @media (max-width: 768px) {
                display: block;
                margin-top: 400px;
                
                }

            .boxes {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                // width: 400px;
                // height: 300px;
                margin: 10px;
                margin-top: -490px;
               

                svg{
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    transition: ease-in-out 0.3s;
        
                    &:hover {
                        transform: translate();
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                        transform: translateY(-4px);
                    }
                    cursor: pointer;
                }
                h2 {
                    font-size: 20px;
                    text-align: center;
                    color: grey;

                }

                p {
                    //width: 350px;
                    color:#898989;
                    font-size: 18px;
                    @media (max-width: 768px) {
                       font-size: 15px;
                        
                        }

                }
            }

            .boxes2 {
                margin-top: -2px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                // width: 400px;
                // height: 300px;
                svg{
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    transition: ease-in-out 0.3s;
        
                    &:hover {
                        transform: translate();
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                        transform: translateY(-4px);
                    }
                    cursor: pointer;
                }

                h2 {
                    color: red;
                    font-size: 18px;
                    text-align: center;

                }

                p {
                    color:#898989;
                    font-size: 18px;
                    @media (max-width: 768px) {
                      font-size: 15px;
                        
                        }

                }

            }
            .boxes3 {
              
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                // width: 400px;
                // height: 300px;
                margin: 10px;  
                margin-top: -470px;
                svg{
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    transition: ease-in-out 0.3s;
        
                    &:hover {
                        transform: translate();
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                        transform: translateY(-4px);
                    }
                    cursor: pointer;
                }

                @media (max-width: 768px) {
                    margin-top: 10px;
                    
                    }

                h2 {
                    color: red;
                    font-size: 18px;
                    text-align: center;

                }

                p {
                    color: #898989;
                    font-size: 18px;
                  //  width: 350px;
                    @media (max-width: 768px) {
                        font-size: 15px;
                        
                        }
                }

            }
        }
    }
}