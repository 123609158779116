.uber-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 150px;
    text-align: center;

    img{
        width: 300px;
        height: 200px;
    }

    h1{
        font-size: 40px;
        @media (max-width: 768px) {
            
            font-size: 25px;
 
 
            
            }

    }

    h3{
        font-size: 20px;
        width: 800px;
        color: grey;

        @media(max-width:768px){
            font-size: 18px;
            width: 100%;
        }

    }
   
}