.home-wrapper {
   
    
   
        position: relative;
    
        .carousel {
            position: relative;
            height: 85vh; /* Adjust height as needed */
            overflow: hidden;
        }
    
        .carousel-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            z-index: 100;
            text-align: center;
            width: 100%;

            h2{

                @media(max-width:768px){
                    font-size: 30px;
                }
                font-size: 50px;
                color: rgba(222, 216, 212, 0.94);
            }
            
        }
    
        .carousel-image {
            background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, transparent 60%, rgba(0, 0, 0, 0.7) 100%);
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
        }
    
        .carousel::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6); /* Adjust opacity as needed */
        }
    
        // Other styles...
    
    
    
    


    .uber-uns {
        padding-top: 100px;
        display: flex;
        justify-content: center;
 gap: 20px;
        align-items: center;



        @media (max-width: 768px) {
         
         flex-direction: column;
         align-items: center;
         text-align: center;
        
        }

        .uber-text {

            // h1 {
            //     font-size: 40px;
            //     font-weight: 400;
            //     color: red;
            //     text-transform: uppercase;

            //     @media (max-width: 768px) {
            //         font-size: 40px;
            //         margin-left: 10px;

            //     }
            // }

            h4 {
                color: grey;
                font-weight: bold;
               
                font-size: 30px;
               

                @media (max-width: 768px) {
                  
                    font-size: 23px;
                    margin-left: 15px;

                }
            }

            p {
                color: #898989;
                font-weight: 400;
                width: 500px;
                font-size: 40px;

                @media (max-width: 768px) {
                    font-size: 15px;
                    margin-left: 15px;
                    width: 350px;

                }
            }

            button {
                width: 120px;
                height: 50px;
                background-color: red;
                border: red;
                color: white;
                border-radius: 5px;
                font-size: 15px;
                text-transform: uppercase;

                @media (max-width: 768px) {
                    margin-top: 20px;
                    margin-left: 15px;
                }
            }

            button:hover {
                background-color: transparent;
                color: red;
                border: 1px solid red;
                cursor: pointer;
                transition: 0.5s ease-in-out;
            }
        }

        .uber-img {
            position: relative;

            img {
                width: 650px;
                height: 400px;
            margin-right: -150px;
                // filter: brightness(0.3);


                @media (max-width: 768px) {
                 
                   
                    width: 300px;
                    height: 220px;
                    margin-right: 0px;
                 
                    
                }

            }

            .line-top-left,
            .line-bottom-right {
                position: absolute;
                width: 150px;
                height: 2px;
                background-color: white;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            .line-top-left {
                margin-top: 150px;
                top: 0;
                left: 0;
                transform: rotate(-90deg);
                /* Rotate to make it diagonal */
            }

            .line {
                position: absolute;
                background-color: white;
                width: 100px;
                height: 2px;
                margin-top: -430px;
                margin-left: 73px;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            .line2 {

                position: absolute;
                background-color: white;
                width: 100px;
                height: 2px;
                margin-top: -80px;
                margin-left: 373px;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            .line-bottom-right {
                margin-bottom: 150px;
                bottom: 0;
                right: 0;
                transform: rotate(-90deg);
                /* Rotate to make it diagonal */
            }

            b {
                position: absolute;

                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 200px;

                color: white;

                @media (max-width: 768px) {
                    top: 50%;
                    font-size: 150px;
                }
            }

            p {
                position: absolute;
                top: 65%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 20px;
                color: white;
                text-transform: uppercase;

                @media (max-width: 768px) {
                    font-size: 15px;
                    top: 70%;
                }

            }
        }
    }

    .we {
        padding-top: 100px;

        @media (max-width: 768px) {
            display: block;
        }

        .we-text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media (max-width: 768px) {
                display: block;
                text-align: center;

            }

            h1 {
                text-transform: uppercase;
                color: rgb(73, 72, 72);
                font-weight: normal;
                font-size: 20px;

            }

            h3 {
                color: red;
                font-size: 35px;
                margin-top: -3px;
            }
        }

        .we-box {
            display: flex;
            justify-content: center;
            align-items: center;

        gap: 100px;
            svg {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                transition: ease-in-out 0.3s;

                &:hover {
                    transform: translate();
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                    transform: translateY(-4px);
                }

                cursor: pointer;
            }

            @media (max-width: 768px) {
                display: block;
            }


            .boxes {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 400px;
                height: 300px;
                margin: 10px;

                @media (max-width: 768px) {
                    margin-left: -20px;
                }

                h2 {
                    font-size: 20px;
                    text-align: center;


                }

                p {
                    color: rgb(73, 72, 72);
                    font-size: 18px;
                    text-align: center;

                    @media (max-width: 768px) {
                        font-size: 15px;
                        width: 350px;
                    }


                }
            }

            .boxes2 {

                margin-top: -20px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 400px;
                height: 300px;


                @media (max-width: 768px) {
                    margin-left: -20px;
                }


                h2 {
                    font-size: 18px;
                    text-align: center;

                }

                p {
                    color: rgb(73, 72, 72);
                    font-size: 18px;

                    @media (max-width: 768px) {
                        font-size: 15px;
                        width: 350px;
                    }
                }

            }
        }
    }

    .partners {
        padding-top: 100px;
        padding-bottom: 100px;


        .we-text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h1 {
                text-transform: uppercase;
                color: rgb(73, 72, 72);
                font-weight: normal;
                font-size: 20px;

            }

            h3 {
                color: red;
                font-size: 35px;
                margin-top: -3px;
            }
        }

        .partner-boxes {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            cursor: pointer;


            @media (max-width: 768px) {
                display: block;
                text-align: center;
            }

            .boxes {
                img {
                    margin-top: 10px;
                    width: 300px;
                    height: 200px;

            object-fit: contain;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    transition: ease-in-out 0.3s;

                    &:hover {
                        transform: translate();
                        -webkit-filter: grayscale(0%);
                        filter: grayscale(0%);
                        transform: translateY(-4px);
                    }

                }

                h1 {
                    font-weight: normal;
                    font-size: 18px;
                    text-align: center;
                    color: rgb(73, 72, 72);
                    letter-spacing: 1.5px;
                }
            }
        }
    }
}