.header {
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(211, 211, 211, 0.9); /* Adjust the last value (alpha) for opacity */
    z-index: 999999;
    
    

    @media (max-width: 768px) {
        //margin-left: -20px;
        position: sticky;
  
       
        //height: 100%;
    }

    img {
        width: 250px;
        height: 70px;
        margin-top: 5px;
        margin-left: 50px;
      

        @media (max-width: 768px) {
            margin-left: 10px;

        }
    }

    ul {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        margin-right: 50px;

        li {
            padding-left: 10px;

            a {
                text-decoration: none;
                font-size: 18px;
                font-weight: 450;
                color:grey;
                text-transform: uppercase;
                font-size: 16px;
                margin-left: 20px;

                &:hover {
                    color: black;


                }
            }

            .active-link {
                color: black;

                font-weight: bold;
            }
        }
    }

    .btn-menu {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 13px;
        display: none;
        cursor: pointer;
        color: #db1e32;
        order: 3;

        svg {
            width: 30px;
            height: 30px;

        }

        @media (max-width: 768px) {

            display: block;

        }
    }
}



@media (max-width: 768px) {

    .header ul {
        opacity: 0;
        transition: all 0.7s ease-in-out;
        z-index: -1;
        position: fixed;
        top: 0;
        left: -100vw;
        background-color: #FFF;
        height: 100vh;
        width: 100%;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.header-menu {
            opacity: 1;
            z-index: 100;
            left: 0;
        }

        li a {
            display: flex;
            font-size: 24px;
            font-weight: bolder;
            margin-top: 8px;
            margin-bottom: 8px;
        }



    }

}

.lang {
    background-color: transparent;
    border: 1px solid #eee;
    margin-left: 20px;
    cursor: pointer;
}

.login {
    margin-top: 100px;

  
    .modal-content {
  @media(max-width: 768px){
       width: 350px;
     }
        h2 {
            padding-top: 50px;
            text-align: center;
        }

        .form-group {
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 10px;
            margin-top: 20px;


            label {
                font-size: 20px;
                background-color: white;
                margin-bottom: 8px;

            }

            input {
                width: 50%;
                height: 30px;
                background-color: white;

                @media(max-width: 768px){
            
                    width:60%;
                    
                 }
            }


        }


    }

    button {
        width: 150px;
        margin-left: 220px;
        height: 40px;
        font-size: 15px;
        margin-top: 20px;
        background-color: #3e3e3e;
        border: #898989;
        color: white;
        border-radius: 10px;
        @media(max-width: 768px){
            
           margin-left: 90px;
            
         }

    }
  
} 
 .btn{
        width: 80px;
        height: 25px;
        background-color: transparent;
        border: 1px solid #898989;
        border-radius: 10px;
        margin-left: 20px;
        font-size: 14px;
        color: #898989;
        cursor: pointer;
        text-transform: uppercase;
    }
    .btn2{
        width: 150px;
        height: 25px;
        background-color: transparent;
        border: 1px solid #898989;
        border-radius: 10px;
        margin-left: 20px;
        font-size: 14px;
        color: #898989;
        cursor: pointer;
        text-transform: uppercase;
    }