.daten{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 150px;
    margin-bottom: 150px;
    text-align: center;

    h1{
        font-size: 40px;
        @media(max-width:768px){
          font-size: 20px;
        }
    }
    p{
        width: 1000px;

        @media(max-width:768px){
            width: 300px;
            font-size: 15px;
        }
    }

  
}